import { Component } from '@angular/core';
import { BackendService } from './backend.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


  title = 'eumathein';

  message: string ='';

  constructor(private backendService: BackendService) {}

  ngOnInit() {
    this.backendService.getData().subscribe(data => {
      this.message = data.message;
      console.log('message',this.message);
    });
  }
}
